<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Roles</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1" v-if="hasPermission('AUTH_LIST_ROLE')">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <v-text-field label="Name" v-model="query.name" single-line outlined @change="search($event, 'name')"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('AUTH_LIST_ROLE')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="hasPermission('AUTH_CREATE_ROLE')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showRoleForm('CREATED')">Create Role</v-btn>
                                <role-form ref="roleForm" :selected-item="selectedRole" :is-show="showFormDialog" :form-type="roleFormType" :authorities="authorities" v-on:close-dialog="closeDialog" v-on:save-role="saveRole" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayRoles" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalRoles" :footer-props="{'items-per-page-options': [5]}" class="elevation-2 mt-4 px-4">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewRole(item)">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <v-btn fab dark x-small color="green" @click.stop="showRoleForm('UPDATE', item)" v-if="hasPermission('AUTH_UPDATE_ROLE')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import RoleForm from '@/views/components/RoleForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Roles',
    components: {
        Loading,
        RoleForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: "",
            },
            headers: [],
            displayRoles: [],
            remainderRoles: [],
            authorities: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            roleFormType: 'CREATE',
            selectedRole: {},
            page: 1,
            totalRoles: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 5
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                name: ''
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
    },
    async mounted() {
        this.setHeaders()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const roleListResponse = await API.getRoles(pageNumber, limit, this.query)
                if (!roleListResponse || roleListResponse.error) {
                    //error getting data
                    console.log(`${roleListResponse.error}`)
                } else {
                    var filteredList = roleListResponse.roles
                    if (page > 1 && this.remainderRoles.length > 0) {
                        filteredList = this.remainderRoles.concat(filteredList)
                    }
                    this.totalRoles = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayRoles = filteredList.slice(0, end)
                    this.remainderRoles = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalRoles / itemsPerPage
                    const hasMoreResponse = await API.getRoles(this.totalRoles, 1, this.query)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        //error getting data
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.roles
                        if(hasMoreList.length > 0) {
                          this.totalRoles = this.totalRoles + 1
                          this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
          try {
                const listResponse = await API.getAllAuthorities()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.authorities = listResponse.authorities
                }
            } catch (e) {
                console.log(e)
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Role Name',
                    value: 'name'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showRoleForm(what, item = {}) {
            this.roleFormType = what
            this.selectedRole = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveRole(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
              console.log(value)
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new role <strong><i>${value.name}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createRole(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created role <strong><i>${value.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.roleForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating role <strong><i>${value.name}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateRole(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated role <strong><i>${value.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.roleForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewRole(role) {
            this.details.title = role.name
            this.details.item = role
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
