<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Role</span>
                <span class="headline" v-else>Create New Role</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Name*" :readonly="formType === 'UPDATE'" @input="v => { form.name = v ? v.toUpperCase() : undefined}"  v-model="form.name" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Description*"  v-model="form.description" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-select :items="authList" item-text="name" item-value="id" label="Authorities*" v-model="form.authorities" required :rules="[rules.requiredSelection]" multiple return-object>
                                  <v-list-item
                                    slot="prepend-item"
                                    ripple
                                    @click="toggle"
                                    v-if="authList.length > 0"
                                  >
                                    <v-list-item-action>
                                      <v-icon :color="form.authorities.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>Select All</v-list-item-title>
                                  </v-list-item>
                                  <v-divider
                                    slot="prepend-item"
                                    class="mt-2"
                                  ></v-divider>
                                  <v-divider
                                    slot="append-item"
                                    class="mb-2"
                                  ></v-divider>
                                </v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        authorities: Array,
        selectedItem: Object,
    },
    computed: {
        populatedForm() {
            return {
                name: this.form.name,
                description: this.form.description,
                authorities: JSON.stringify(this.form.authorities.map(({id, name}) => ({id, name}))),
                formType: this.formType
            }
        },
        haveAllAuthority () {
          return this.form.authorities.length === this.authList.length
        },
        selectedAuthority () {
          return this.form.authorities.length > 0 && !this.haveAllAuthority
        },
        icon () {
          if (this.haveAllAuthority) return 'mdi-close-box'
          if (this.selectedAuthority) return 'mdi-minus-box'
          return 'mdi-checkbox-blank-outline'
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        authorities: {
            handler(value) {
                this.authList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            authList: this.authorities ?? [],
            form: {
              authorities: []
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        toggle () {
          this.$nextTick(() => {
            if (this.haveAllAuthority) {
              this.form.authorities = []
            } else {
              this.form.authorities = this.authList.slice()
            }
          })
        },
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-role', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    name: undefined,
                    description: undefined,
                    authorities: []
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    name: this.selectedItem.name,
                    description: this.selectedItem.description,
                    authorities: this.selectedItem.authorities ?? []
                }
            }
        }
    }
}
</script>
